// import Vue from "vue";
// import Components from "./components";
// import "./app.css";

// Vue.config.productionTip = false;

// new Vue({
//   el: "#vue-search",
//   delimiters: ["${", "}"]
// });

export default function () {
  // console.clear()

  const wikiUrl = 'https://en.wikipedia.org'
  const params = 'action=query&list=search&format=json&origin=*'

  //test url
  // const apiUrl = 'http://ac3-craft-vue.test/api/subsections.json?query='
  //live url
  const apiUrl = 'https://teenconcussion.vch.ca/api/subsections.json?query='

  new Autocomplete('#autocomplete', {

    // Search function can return a promise
    // which resolves with an array of
    // results. In this case we're using
    // the Wikipedia search API.
    search: input => {
      // const url = `${wikiUrl}/w/api.php?${params}&srsearch=${encodeURI(input)}`
      const url = `${apiUrl}${encodeURI(input)}`

      return new Promise(resolve => {
        if (input.length < 3) {
          return resolve([])
        }

        fetch(url)
          .then(response => response.json())
          .then(data => {
            // const results = data.query.search.map((result, index) => {
            const results = data.data.map((result, index) => {
              return { ...result, index }
            })
            resolve(results)
          })
      })
    },

    // Control the rendering of result items.
    // Let's show the title and snippet
    renderResult: (result, props) => {
      let summary = '';
      if (result.pageSummary != null) {
        summary = result.pageSummary;
      }
      let group = ''
      if (result.index % 3 === 0) {
        group = `<div class="results-header"><strong>Results</strong></div>`
      }
      return `
      <li ${props}>
        <a href="${result.url}">
          <div class="search-title">
            ${result.title}
          </div>
          <div class="search-snippet">
            ${summary}
          </div>
        </a>
      </li>
    `
    },

    // Wikipedia returns a format like this:
    //
    // {
    //   pageid: 12345,
    //   title: 'Article title',
    //   ...
    // }
    // 
    // We want to display the title
    getResultValue: result => result.title,

    // Open the selected article in
    // a new window
    // onSubmit: result => {
    //   window.open(`${wikiUrl}/wiki/${encodeURI(result.title)
    //     }`)
    // }
  })
}