// sourced from https://codeorum.com/tutorials/simple-accordion-collapsible-element-with-vanilla-js-and-simple-animation-effect

function initAcc(elem, option){
    // console.log(elem);
    document.addEventListener('click', function (e) {
        if (!e.target.matches('#'+elem.id+' .a-header')) return;
        else{
            if(!e.target.parentElement.classList.contains('active')){
                if(option==true){
                    var elementList = document.querySelectorAll('#'+elem.id+' .a-container');
                    Array.prototype.forEach.call(elementList, function (e) {
                        e.classList.remove('active');
                    });
                }            
                e.target.parentElement.classList.add('active');
            }else{
                e.target.parentElement.classList.remove('active');
            }
        }
    });
}

export default function () {
    let accordions = document.querySelectorAll(".accordion");
    accordions.forEach(el => {
        initAcc( el, true);
    })

    // apply js functions to solo accordions (not accordino group)
    let sAccordions = document.querySelectorAll(".solo-accordion")
    sAccordions.forEach(el => {
        initAcc( el, false);
    })
}
