//import utility functions
import * as utility from "../helpers/utilities";

export default function () {
    addEditBtnListeners();
    addHideBtnListeners();

    let rqContainers = document.querySelectorAll(".rq-activity");

    if (rqContainers.length > 0) {
        rqContainers.forEach(rqCon => {
            let rqOptions = rqCon.querySelectorAll(".rq-item");

            rqOptions.forEach(el => {
                let saveCookie = utility.getCookie(rqCon.id);

                markCheckboxes(el, saveCookie);

                el.addEventListener('click', function (e) {
                    let savedOptions = "";
                    saveCookie = utility.getCookie(rqCon.id);
                    if (saveCookie != "") {
                        let currSaved = saveCookie.split('&');
                        let alreadySaved = false;

                        // check if already saved
                        currSaved.forEach(option => {
                            if (option === el.id) {
                                alreadySaved = true;
                            }
                        });
                        // rebuild savedOptions string to save to cookie
                        currSaved.forEach(option => {
                            if (alreadySaved) {
                                // console.log('Already saved route.')
                                if (option === el.id) {
                                    utility.toast("Removed from Recovery Plan.");
                                    // do nothing. Don't add back in.

                                    //POSSIBLY ADD CODE TO CHECK IF ANYTHING IS LEFT IN SECTION
                                } else {
                                    // console.log(option);
                                    // add existing options back as normal
                                    savedOptions += option + '&';
                                }
                            } else {
                                // else add existing options back as normal
                                savedOptions += option + '&';
                            }
                        });
                        if (!alreadySaved) {
                            // console.log('not already saved route');
                            savedOptions += el.id + '&';
                            utility.toast("Added to Recovery Plan.");
                        }
                        // console.log('savedOptions before substring op: '+savedOptions);
                        savedOptions = savedOptions.substring(0, savedOptions.length - 1);
                    } else {
                        // console.log('savedCookie = null')
                        savedOptions = el.id;
                        utility.toast("Added to Recovery Plan.");
                    }
                    // console.log("save savedOptions string");
                    // console.log(savedOptions);
                    utility.setCookie(rqCon.id, savedOptions, 365);
                    let coreId = el.id.split('_');
                    updateRQlist(coreId[0]);
                });
            });
            updateRQlist(rqCon.id);
            unhideRqOnLoad(rqCon);
        });
    }
}

function markCheckboxes(e, saveList) {
    let currSaved = saveList.split('&');

    currSaved.forEach(option => {
        if (option === e.id) {
            e.checked = true;
        }
    });
}

function addEditBtnListeners() {
    let editBtnsArray = document.querySelectorAll('.rq-edit-btn');

    editBtnsArray.forEach(el => {
        el.addEventListener('click', () => {
            let idSplit = el.id.split("_");
            let popup = document.querySelector("#" + idSplit[0] + "_popup");
            let overlay = document.querySelector("#overlay");
            overlay.classList.add("active");
            popup.classList.remove("display-none");
        });
    })
}

function addHideBtnListeners() {
    let hideBtnsArray = document.querySelectorAll('.rq-hide-btn');

    if (hideBtnsArray.length) {
        hideBtnsArray.forEach(el => {
            el.addEventListener('click', (e) => {
                let idSplit = el.id.split("_");
                let container = document.querySelector("#" + idSplit[0] + "_activity-container");
                let list = document.querySelector("#rq-" + idSplit[0] + "_list");
                let header = container.querySelector(".rq-list-heading");
                if (container.classList.contains("no-print")) {
                    el.innerHTML = "Hide";
                    header.classList.remove("disabled");
                    container.classList.remove("no-print");
                    list.classList.remove("display-none");
                } else {
                    el.innerHTML = "Unhide";
                    header.classList.add("disabled");
                    container.classList.add("no-print");
                    list.classList.add("display-none");
                }
            });
        });
    }
}

function updateRQlist(id) {
    //only run if on recovery plan page
    if (document.querySelector("h1.rq-print-title")) {
        if (document.querySelectorAll(".rq-list-item").length) {
            let cookie = utility.getCookie(id);
            let container = document.querySelector("#" + id + "_list");
            if (cookie != "") {
                let cookieArray = cookie.split("&");
                let itemsArray = container.querySelectorAll(".rq-list-item");

                itemsArray.forEach(item => {
                    item.classList.add("display-none");
                    // console.log("adding display-none class");
                    cookieArray.forEach(cookie => {
                        if (item.id === cookie) {
                            item.classList.remove("display-none");
                        }
                    });
                });
            } 
            else {
                //if cookie is empty
                let itemsArray = container.querySelectorAll(".rq-list-item");
                itemsArray.forEach(item => {
                    item.classList.add("display-none");
                });
                let trimmedID = id.replace("rq-", "");
                let hideBtn = document.getElementById(trimmedID+"_hide-btn");
                if (hideBtn.innerHTML === "Hide") {
                    hideBtn.click();
                }
            }
        }
    }
}

function unhideRqOnLoad(rqActivityCon) {
    //only run if on recovery plan page
    if (document.querySelector("h1.rq-print-title")) {
        if (document.querySelectorAll(".rq-list-item").length) {
            let checkboxArray = rqActivityCon.querySelectorAll(".rq-item.custom-checkbox");
            let slug = rqActivityCon.id.slice(3);
            let container = document.querySelector("#" + slug + "_activity-container");
            let hideBtn = container.querySelector(".rq-hide-btn");
            let foundCheckedItem = false;
            checkboxArray.forEach(cb => {
                if (foundCheckedItem == false && cb.checked === true) {
                    hideBtn.click();
                    foundCheckedItem = true;
                }
            });
        }
    }
}