import Vue from "vue";
import Components from "./components";

//require packages
// var autocomplete = require();
// import Autocomplete from "vue2-autocomplete-js";

//import styles 
import "./app.css";
import "@/scss/_base.scss";
import * as utility from "./js/helpers/utilities";
import darkmode from "./js/darkmode";
import search from "./js/search";
import topicTracker from "./js/partials/topic-progress";
import menus from "./js/menus";
import nav from "./js/nav";
import index from "./js/index";
import richText from "./js/partials/process-rich-text";
import pagePreferences from "./js/page-preferences";
import subsection from "./js/partials/subsection";
import hotspots from "./js/partials/hotspots";
import favouritePage from "./js/partials/favourite-page";
import accordions from "./js/partials/accordions";
import multipleChoice from "./js/partials/multiple-choice";
import rqActivity from "./js/partials/recovery-quest";
import survey from "./js/partials/survey";
import removeFav from "./js/partials/remove-fav";
import share from "./js/partials/share";
import popups from "./js/partials/popups";

// import Vue components
// import SearchCraft from './components/SearchCraft'


Vue.config.productionTip = false

// new Vue({
//   el: '#search',
//   delimiters: ['${', '}'],
//   components: {SearchCraft}
// })

// SearchCraft(Vue);

// if (document.getElementById('search')){
//   new Vue({
//     el: "#search",
//     delimiters: ["${", "}"]
//   });
// };

// if (document.getElementById('myguide')) {
//   new Vue({
//     el: "#myguide",
//     delimiters: ["${", "}"]
//   });
// };

darkmode();
menus();
// Autocomplete();
index();
nav();
search();
richText();
pagePreferences();
subsection();
hotspots();
favouritePage();
accordions();
multipleChoice();
rqActivity();
survey();
removeFav();
share();
topicTracker();
popups();

// define a dependency bundle
// loadjs(
//   [
//     '{{ baseUrl }}js/vue.min{{ staticAssetsVersion }}.js',
//   ],
//   'vue'
// );
// loadjs(
//   [
//     '{{ baseUrl }}js/vue2-autocomplete.min{{ staticAssetsVersion }}.js',
//   ],
//   'vue-autocomplete'
// );
// loadjs.ready(['vue', 'vue-autocomplete'], {
//   success: function () {
//     new Vue({
//       el: '#nav-menu',
//       components: {
//         autocomplete: Vue2Autocomplete
//       },
//       delimiters: ['${', '}'],
//       data: {
//         menuOpen: false,
//       },
//       methods: {
//         prerenderLink: function (e) {
//           var head = document.getElementsByTagName("head")[0];
//           var refs = head.childNodes;
//           ref = refs[refs.length - 1];

//           var elements = head.getElementsByTagName("link");
//           Array.prototype.forEach.call(elements, function (el, i) {
//             if (("rel" in el) && (el.rel === "prerender"))
//               el.parentNode.removeChild(el);
//           });

//           var prerenderTag = document.createElement("link");
//           prerenderTag.rel = "prerender";
//           prerenderTag.href = e.currentTarget.href;
//           ref.parentNode.insertBefore(prerenderTag, ref);
//         },
//         toggle: function () {
//           this.menuOpen = !this.menuOpen;
//         },
//         itemSelected: function (data) {
//           ga('send', 'pageview', data.searchUrl);
//           window.location.href = data.url;
//         },
//         processJsonData: function (json) {
//           return json.data;
//         }
//       },
//     });
//   }
// });
