function initMC(elem) {
    // console.log(elem);
    let btn = elem.querySelector(".mc-btn");
    btn.addEventListener('click', function (e) {
        // console.log(elem);
        let answers = elem.querySelectorAll("li.answer");
        // console.log(answers);
        let mcContainer = this.parentNode;
        mcContainer.classList.add("reveal-answer");
        answers.forEach(a=>{
            let cb = a.querySelector(".custom-checkbox");
            if(!cb.checked && a.classList.contains("correct")){
                // console.log(a.querySelector(".custom-checkbox"));
                a.classList.add("answered-incorrect");
            }
        });

        this.innerHTML = "All are correct";
        this.classList.add("disabled");
    });
}
export default function () {
    let mcActivity = document.querySelectorAll(".mcActivity");
    mcActivity.forEach(el => {
        initMC(el);
    })
}
