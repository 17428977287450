<template>
<!-- <div>
<h4>Testing SearchCraft Component</h4>
  <div class="autocomplete-container">
    <input
      id="autocomplete"
      class="autocomplete-input"
      placeholder="Search"
      aria-label="Search"
    />
    <ul class="autocomplete-result-list"></ul>
  </div>
</div> -->

  <autocomplete
    url="/api/subsections"
    anchor="title"
    label="writer"
    :on-select="getData"
    param="query"
    limit="5"
    >
  </autocomplete>
</template>

<script>
// import Autocomplete from "vue2-autocomplete-js";
// require('vue2-autocomplete-js/dist/style/vue2-autocomplete.css')

export default {
  components: { Autocomplete },
  methods: {
    getData(obj) {
      console.log(obj);
    }
  }
};
//     // define a dependency bundle
//     loadjs(
//         [
//             '{{ baseUrl }}js/vue.min{{staticAssetsVersion}}.js',
//         ],
//         'vue'
//     );
//     loadjs(
//         [
//             '{{ baseUrl }}js/vue2-autocomplete.min{{staticAssetsVersion}}.js',
//         ],
//         'vue-autocomplete'
//     );
//     loadjs.ready(['vue', 'vue-autocomplete'], {
//         success: function() {
//             new Vue({
//                 el: '#nav-menu',
//                 components: {
//                   autocomplete: Vue2Autocomplete
//                 },
//                 delimiters: ['${', '}'],
//                 data: {
//                     menuOpen: false,
//                 },
//                 methods: {
//                     prerenderLink: function(e) {
//                         var head = document.getElementsByTagName("head")[0];
//                         var refs = head.childNodes;
//                         ref = refs[ refs.length - 1];

//                         var elements = head.getElementsByTagName("link");
//                         Array.prototype.forEach.call(elements, function(el, i) {
//                             if (("rel" in el) && (el.rel === "prerender"))
//                                 el.parentNode.removeChild(el);
//                         });

//                         var prerenderTag = document.createElement("link");
//                         prerenderTag.rel = "prerender";
//                         prerenderTag.href = e.currentTarget.href;
//                         ref.parentNode.insertBefore(prerenderTag,  ref);
//                     },
//                     toggle: function() {
//                         this.menuOpen = !this.menuOpen;
//                     },
//                     itemSelected: function(data) {
//                       ga('send', 'pageview', data.searchUrl);
//                       window.location.href = data.url;
//                     },
//                     processJsonData: function(json) {
//                         return json.data;
//                     }
//                 },
//             });
//         }
//     });
</script>

<style>

</style>
