import * as utility from "./helpers/utilities";

export default function () {
  document.addEventListener('DOMContentLoaded', () => {
    setDM();

    // DARK MODE TOGGLE
    //get all dark-mode elements
    const bodyTag = document.getElementById("body");
    const dmState = bodyTag.classList;
    const dmToggles = Array.prototype.slice.call(document.querySelectorAll('.dm-toggle'), 0);
    var dmCookie = utility.getCookie("dmState");

    // add Event Listeners
    if (dmToggles.length > 0) {
      dmToggles.forEach(el => {
        el.addEventListener('click', () => {
          if (dmState.contains("dark-mode")) {
            bodyTag.classList.toggle('dark-mode');
            utility.setCookie("dmState", false, 365);
          } else {
            bodyTag.classList.toggle('dark-mode');
            utility.setCookie("dmState", true, 365);
          }
        })
      })
    }
  });
}

//Apply Darkmode Preferences
function setDM(){
  const dmCookie = utility.getCookie("dmState");
  if (dmCookie === ""){
    const prefersDarkScheme = window.matchMedia("(prefers-color-scheme: dark)");

    if (prefersDarkScheme.matches) {
      document.body.classList.add("dark-mode");
      utility.setCookie("dmState", true, 365);
    } else {
      document.body.classList.remove("dark-mode");
      utility.setCookie("dmState", false, 365);
    }
  } 
  // else {
  //   if (dmCookie == "true") {
  //     document.body.classList.add("dark-mode");
  //     utility.setCookie("dmState", true, 365);
  //   } else {
  //     document.body.classList.remove("dark-mode");
  //     utility.setCookie("dmState", false, 365);
  //   }
  // }
}