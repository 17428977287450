//import utility functions
import * as utility from "../helpers/utilities";

export default function () {
    //after document loads, add all open, close etc. eventlisteners
    document.addEventListener('DOMContentLoaded', () => {

        // check if on subsection page
        if (document.querySelector("#share-btn")) {
            let shareBtn = document.querySelector("#share-btn");
            shareBtn.addEventListener("click", () => {
                let str = window.location.toString();
                console.log("Share btn clicked");
                if (str.includes("myguide")) {
                    //check if myguide page
                    if (document.querySelector(".myguide-content")) {
                        console.log("we're on the guide page");
                        let baseUrl = window.location.hostname;
                        let name = utility.getCookie("name");
                        let saveFilter = utility.getCookie("saveFilter");
                        let savedPages = utility.getCookie("saved-pages");
                        let url = baseUrl + "/shared-guide?" + saveFilter;
                        if (name != ""){
                            url = url + "&&name=" + name;
                        } else {
                            url = url + "&&name=no_name";
                        }
                        if (savedPages != ""){
                            url = url + "&&" + savedPages;
                        }
                        
                        utility.copyStringToClipboard(url);
                    } else {
                        let url = str.replace("myguide", "education-content");
                        utility.copyStringToClipboard(url);
                    }
                } else {
                    utility.copyStringToClipboard(str);
                }
                utility.toast("Page link copied to clipboard");
            });
        }
    })
}