//import utility functions
import * as utility from "../helpers/utilities";

// Set event listeners on all toggles with utillity function: setToggleFunctions() and pass in favourites cookie update function
export default function () {
  document.addEventListener('DOMContentLoaded', () => {
    // ADD EVENT LISTENERS
    const saveToggles = Array.prototype.slice.call(document.querySelectorAll('.save-toggle input'), 0);
    if (saveToggles.length > 0) {
      saveToggles.forEach(el => {
        el.addEventListener('click', () => {
          updateSavedPages();
          syncSavedToggles();
        })
      })
    }
    // Update toggles to match saved state
    syncSavedToggles();
  })
}

// declare function to update favourites cookie
function updateSavedPages() {

  // Get savedPages cookie
  let savedPages = utility.getCookie('saved-pages');
  let thisPage = window.location.pathname.substring(1);
  thisPage =  thisPage.split('/')[1];
  let savedPagesString = "";
  if (savedPages != "") {
    // If not empty
    let alreadySaved = false;
    let currSaved = savedPages.split('&'); //split cookie into array

    // check if already saved
    currSaved.forEach(page => {
      if (page === thisPage) {
        alreadySaved = true;
      }
    });
    
    // Rebuild string
    currSaved.forEach(page => {
      if (page === thisPage) {
        // console.log("page match found");
        if (alreadySaved === true) {
          // if already there, do not add add this time (removes it)
          utility.updateToggles("off", '.save-toggle input');
        }
      } else {
        savedPagesString += page + '&';
      }
    });
    if (alreadySaved === false) {
      savedPagesString += thisPage;
    } else {
      savedPagesString = savedPagesString.substring(0, savedPagesString.length - 1);
    }
  } else {
    // Else if empty, no saved pages yet. Add page
    savedPagesString = thisPage;
  }
  utility.setCookie("saved-pages", savedPagesString, 365);

  // console.log(savedPagesString);
}

function syncSavedToggles(){
  let savedPages = utility.getCookie('saved-pages');
  let thisPageSlug = window.location.pathname.substring(1);
  if (thisPageSlug.includes("myguide/") || thisPageSlug.includes("education-content/") || thisPageSlug.includes("shared-guide/")) {
    thisPageSlug = thisPageSlug.split('/')[1];
  }

  if (savedPages != "") {
    let savedPagesArray = savedPages.split('&'); //split cookie into array
    savedPagesArray.forEach(page => {
      if (page === thisPageSlug) {
        utility.updateToggles("on", '.save-toggle input');
      }
    });
  }
}