import * as utility from "../helpers/utilities";

export default function () {
    document.addEventListener('DOMContentLoaded', () => {
        // ADD EVENT LISTENERS
        const surveyBtns = Array.prototype.slice.call(document.querySelectorAll('.survey-btn'), 0);
        if (surveyBtns.length > 0) {
            surveyBtns.forEach(el => {
                el.addEventListener('click', () => {
                    surveyBtn(el);
                })
            })
        }
    });
}

function surveyBtn(el) {
    // console.log(el);
    // console.log(el.id + " btn pressed");
    if (!el.classList.contains("disabled")) {
        // console.log("not disabled");
        let currSlide = document.querySelector(".survey-section.active");
        let prevSlide = currSlide.previousElementSibling;
        let nextSlide = currSlide.nextElementSibling;

        switch (el.id) {
            case "prev-btn":
                currSlide.classList.remove("active");
                prevSlide.classList.add("active");
                break;
            case "next-btn":
                currSlide.classList.remove("active");
                nextSlide.classList.add("active");
                break;
            case "survey-submit-btn":
                console.log("submit button");
                saveSurvey();
                break;
        }
        updateSurveyBtnStates();
    };
}

function updateSurveyBtnStates() {
    if (document.querySelector("#section-1").classList.contains("active")) {
        document.querySelector("#prev-btn").classList.add("disabled");
    } else {
        document.querySelector("#prev-btn").classList.remove("disabled");
    }

    if (document.querySelector("#section-4").classList.contains("active")) {
        document.querySelector("#next-btn").classList.add("display-none");
        document.querySelector("#survey-submit-btn").classList.remove("display-none");
    } else {
        console.log(document.querySelector("#next-btn"));
        document.querySelector("#next-btn").classList.remove("display-none");
        document.querySelector("#survey-submit-btn").classList.add("display-none");
    }
}

//FUNCTION TO SET FILTER SELECTION
function saveSurvey() {
    var saveFilterString = "";

    // console.log('saveSurvey() function is running');

    var elements = document.getElementById("survey-container").getElementsByTagName("input");
    // console.log(elements);

    for (var i = 0, element; element = elements[i++];) {
      if (element.checked == true) {
        // console.log(element.name + "=" + element.value);
        saveFilterString = saveFilterString + element.name + "=" + element.value + "&";
      }
    }
    //slice last & character
    saveFilterString = saveFilterString.substring(0, saveFilterString.length - 1);

    // console.log(saveFilterString);
    //store filter selection to Local Storage if string isn't blank
    if (saveFilterString != "") {
      //Saves Filter selection to Cookies
      utility.setCookie("saveFilter", saveFilterString, 365);
    //   console.log("Filter parameters saved to Cookies");
    }
    window.location.href = "/myguide";

}