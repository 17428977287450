//import utility functions
import * as utility from "../helpers/utilities";

export default function () {
    //after document loads, add all open, close etc. eventlisteners
    document.addEventListener('DOMContentLoaded', () => {

        // check if on subsection page
        if (document.querySelectorAll(".myguide-content").length > 0) {
            let topicSections = document.querySelectorAll(".track-progress-container");
            // console.log(topicSections);
            topicSections.forEach(section => {
                let marker = section.querySelector(".progress-marker");
                let linksList = section.querySelectorAll(".subsection-link");
                let allVisited = true;
                // check if there are any links
                if (linksList.length > 0) {
                    linksList.forEach(link => {
                        // console.log(link);
                        // trawl through read-pages cookie and add visited classes next time.
                        if (link.classList.contains("read") != true) {
                            allVisited = false;
                        }
                    })
                    if (allVisited) {
                        section.classList.add("read");
                    }
                } else {
                    //remove section
                    section.remove();
                }
            });
        }
    })
}