//import utility functions
import * as utility from "./helpers/utilities";

export default function () {
    document.addEventListener('DOMContentLoaded', () => {
        copyright();
        if (document.querySelector("#survey_popup")) {
            let surveyPopup = document.querySelector('#survey_popup');
            let surveyBtn = document.querySelector('#launch-btn');
            let redoBtn = document.querySelector('#redo-survey-btn');
            let submit = document.querySelector('#name-submit-btn');
            if (surveyBtn) { utility.openPopup(surveyBtn, surveyPopup, "launch")}
            if (redoBtn) { utility.openPopup(redoBtn, surveyPopup) }

            submit.addEventListener("click", () => {
                let nameString = document.querySelector("#name-input").value;
                utility.setCookie("name", nameString, 365);
                window.location.href = "/survey";
            });
        }
        if (document.querySelector('#welcome-popup')) {
            openWelcome();
        }
        if (document.querySelector('#print-btn')) {
            let printBtn = document.querySelector('#print-btn');
            printBtn.addEventListener('click', () => {
                console.log('print btn clicked');
                window.print();
            }); 
        }
    });
}

//Set copyright date
function copyright() {
    let cYear = document.getElementById("cyear");
    let d = new Date();
    let year = d.getFullYear();
    cYear.innerHTML = year;
}

function openWelcome() {
    let soCookie = utility.getCookie('seen-onboarding');
    if (soCookie != 'true') {
        overlay.classList.add("active");
        document.querySelector("#welcome-popup").classList.remove("invisible");
    }
}

