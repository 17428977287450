//import utility functions
import * as utility from "./helpers/utilities";

//Get elements
const overlay = document.getElementById('overlay');
const closeNavBtn = document.getElementById('close-nav-btn');
const menuBtn = document.getElementById('menu-btn');
const sideMenu = document.getElementById('side-menu');
const helpBtn = document.getElementById('help-btn');
const helpModal = document.getElementById('help-popup');
const feedbackBtn = document.getElementById('feedback-btn');
const feedbackPopup = document.getElementById('feedback-popup');
const closePopupBtns = document.querySelectorAll('.close-popup-btn');

// add elements to arrays
const closeOverlaysArray = [];
closeOverlaysArray.push(overlay, closeNavBtn);

export default function () {
    //after document loads, add all open, close etc. eventlisteners
    document.addEventListener('DOMContentLoaded', () => {

        // ADD EVENT LISTENERS
        // Open menu button
        menuBtn.addEventListener('click', () => {
            if (sideMenu.classList.contains("active") != true) {
                overlay.classList.add("active");
                sideMenu.classList.add("active");
              }
        });

        //close menus and overlays
        if (closeOverlaysArray.length > 0) {
            closeOverlaysArray.forEach(el => {
                el.addEventListener('click', () => {
                    closeOverlays();
                })
            })
        }

        // help button action
        helpBtn.addEventListener('click', () => {
            if (helpBtn.classList.contains("active") != true) {
                overlay.classList.add("active");
                sideMenu.classList.remove("active");
                helpModal.classList.remove("invisible");
            }
        });

        // feedback button action
        feedbackBtn.addEventListener('click', () => {
            if (feedbackBtn.classList.contains("active") != true) {
                overlay.classList.add("active");
                sideMenu.classList.remove("active");
                feedbackPopup.classList.remove("invisible");
            }
        });

        // listener on overlay 
        overlay.addEventListener('click', () => {
            utility.closePopups();
        });

        // listener on all popup close buttons
        closePopupBtns.forEach(e => {
            e.addEventListener('click', ()=>{
                utility.closePopups();
            })
        })
    })
}

function closeMenu() {
    if (sideMenu.classList.contains("active")) {
        sideMenu.classList.remove("active");
        overlay.classList.remove("active");
    }
}

function closeOverlays() {
    overlay.classList.remove("active");
    closeMenu();
}