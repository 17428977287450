import * as utility from "../helpers/utilities";

export default function () {
    document.addEventListener('DOMContentLoaded', () => {

        new Glider(document.querySelector('#help-popup .glider'), {
            slidesToShow: 1,
            dots: '#help-dots',
            exactWidth: false,
            arrows: {
                prev: '#help-popup .glider-prev',
                next: '#help-popup .glider-next'
            },
        });

        new Glider(document.querySelector('#welcome-popup .glider'), {
            slidesToShow: 1,
            dots: '#welcome-dots',
            exactWidth: false,
            arrows: {
                prev: '#welcome-popup .glider-prev',
                next: '#welcome-popup .glider-next'
            },
        });
        feedbackPopup();
        cookieBanner();
    });
}

function cookieBanner(){
    //add cookie consent btn and banner handler
    if (document.getElementById("cookieAcceptBtn")) {
        document.getElementById("cookieAcceptBtn").addEventListener("click", () => {
            utility.setCookie("acceptedCookies", "true", 365);
            document.getElementById("cookieConsentBanner").classList.add("invisible");
        });

        if (utility.getCookie("acceptedCookies") != "true") {
            console.log("cookies not accepted yet");
            document.getElementById("cookieConsentBanner").classList.remove("invisible");
        }
    }
}

function feedbackPopup() {
    let el = document.getElementById("feedback-popup");
    let remindBtn = el.querySelector("#remindLater");
    let neverBtn = el.querySelector("#neverShow");

    let pageSettings = el.dataset.pageSettings;
    let threshold = el.dataset.pageThreshold;
    let remindLaterDays = el.dataset.pageReminder;
    let currHandle = window.location.pathname.substring(1);

    //check cookies
    let readCookie = utility.getCookie("readPages"); //get list of articles read
    let neverCookie = utility.getCookie("neverShow");

    //check whether to show popup
    if (neverCookie != "true" && (pageSettings == "allPages" || (pageSettings == "guide" && currHandle == "myguide"))) {
        let readPages = readCookie.split('&'); //split cookie into array
        console.log(readPages.length +" vs threshold: "+ threshold);
        if (readPages.length >= threshold) {
            if (utility.getCookie('remindLater')) {
                console.log("remindLater exists");
                //check timing of remindlater
                let currDate = new Date();
                let remindDate = new Date(getCookie("remindLater"));
                let daysPassed = (currDate - remindDate) / (1000 * 60 * 60 * 24);

                if (daysPassed >= remindLaterDays) {
                    //show popup again
                    let overlay = document.querySelector("#overlay");
                    overlay.classList.add("active");
                    el.classList.remove("invisible");
                }
            } else {
                //show popup
                let overlay = document.querySelector("#overlay");
                console.log(overlay);
                overlay.classList.add("active");
                el.classList.remove("invisible");
            }
        }
    }
    
    //add event listeners to btns
    remindBtn.addEventListener("click", () => {
        let currDate = new Date();
        utility.setCookie("remindLater", currDate, 365);
        utility.setCookie("neverShow", false, 365);
        utility.closePopups();
    });
    neverBtn.addEventListener("click", () => {
        utility.setCookie("neverShow", true, 365);
        utility.closePopups();
    });
}