export default function () {
    document.addEventListener('DOMContentLoaded', () => {
        // SCAN FOR ELEMENTS WITH 'SCANNABLE-CONTENT' CLASS
        // only use this class on div tag wrapper injection of rich text field 'Redactor' from Craft CMS
        let contentArray = document.querySelectorAll('.scannable-content');
        // console.log(contentArray);

        if (contentArray.length > 0) {
            // console.log(contentArray);
            // CHECK CONTENT INSIDE ELEMENT AGAINST GLOSSARY JSON
            // console.log('check if any glossary terms in content');

            contentArray.forEach( el => {
                let aTags = el.querySelectorAll("a");
                aTags.forEach(a => {
                    // console.log(a);
                    if (a.getAttribute('href').toString().includes("education-content")){
                        // console.log("education content detected");
                        a.classList.add("subsection-link");
                    }
                })

            });
            
            for (let i = 0; i < contentArray.length; i++) {
                // console.log(contentArray[i]);
                // for (let j = 0; j < contentArray[i].length; j++) {
                    
                // }
            }

            // REPLACE ANY MATCHING WORDS WITH SPAN TAG WITH HOVER TIP STYLING CLASSES


        } else {
            // console.log('no scannable content to check');
        }

    })

}