export default function () {
    markActivePage();

    if (document.querySelector(".sticky-nav")) {
        window.addEventListener('scroll', function () {
            var top = window.scrollY;
            var height = document.body.getBoundingClientRect().height - window.innerHeight;
            updateProgress(top, height);
        });
    }
}

function updateProgress(num1, num2) {
    var percent = Math.ceil(num1 / num2 * 100) + '%';
    document.getElementById('progress').style.width = percent;
}

function markActivePage() {
    let path = window.location.pathname;
    let navBtns = document.querySelectorAll(".nav-item a");
    navBtns.forEach(e => {
        if (e.pathname == path) {
            e.classList.add("active");
        }
    })
}

