// SCRIPTS TO RUN TOPIC PAGE FEATURES

//import utility functions
import * as utility from "../helpers/utilities";

//Get elements


// add elements to arrays


export default function () {
    //after document loads, add all open, close etc. eventlisteners
    document.addEventListener('DOMContentLoaded', () => {

        // check if on subsection page
        if (document.querySelector("#subsection-page")) {
            //add page to list of read pages cookie
            var readCookie = utility.getCookie('readPages');
            var slug = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1);
            // console.log(slug);

            if (readCookie == "") { //if article is not saved to Read Cookie
                utility.setCookie("readPages", slug, 365);
            } else {
                let readArray = readCookie.split('&'); //split cookie into array
                // console.log(readArray);

                //check if current article matches any entry in readArray
                for (let i = 0; i < readArray.length; i++) {

                    if (slug == readArray[i]) {
                        // console.log('article already saved to readCookie');
                        return true; //break loop and end
                    }
                }
                // console.log('article check else section');
                readCookie = readCookie + "&" + slug;
                utility.setCookie("readPages", readCookie, 365); //set cookie        
            }
        }
    })
}