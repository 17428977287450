//FUNCTION TO SET COOKIES sourced from w3schools
export function setCookie(cname, cvalue, exdays) {
    // console.log("setting "+ cname +" cookie to "+ cvalue);

    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    // console.log(cname + " cookie has been set to " + cvalue);
}

//FUNCTION TO GET COOKIES sourced from w3schools
export function getCookie(cname) {
    // console.log("getting cookie "+ cname);
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

//CHECK IF STRING IS INCLUDED IN URL AND RETURN THE STRING
export function searchUrl(sParam) {
    var sPageURL = window.location.toString();
    if (sPageURL.includes(sParam)) {
        // console.log(sParam + ' is in URL');
        return (sParam);
    } else {
        // console.log('could not find ' + sParam + ' in URL');
    }
};

//RETURN HASH DATA IF AVAILABLE
export function returnHashData() {
    var sPageURL = window.location.toString();
    if (sPageURL.includes('#')) {
        // console.log('data is in URL');
        var splitURL = sPageURL.split('#');
        var currSlug = splitURL[1];
        return (currSlug);
    } else {
        // console.log('could not find hash data in URL');
    }
};

// ARTICLES READ STATE
export function setArticlesRead() {
    // if (isIE()) {
    //   alert('some features of this site may not work because you are using Internet explorer. Please use Chrome or Safari for the best experience');
    // } else {
    console.log('running setArticlesRead');
    var aLinks = document.getElementsByClassName('articleLink');
    // console.log(aLinks);
    var readCookie = getCookie('readArticles');
    var readArray = readCookie.split('&'); //split cookie into array
    // console.log(readArray);

    if (aLinks != "") { //if there are article links on the page
        // console.log('there are aLinks on the page');
        for (i = 0; i < aLinks.length; i++) { //for every article link
            // console.log('loop through aLinks array length');
            var aHref = aLinks[i].getAttribute('href');
            if (aHref.includes('myguide/')) {
                // console.log('aHref includes /myguide/');
                var aHref = aHref.substring(aHref.lastIndexOf('/') + 1)
                // console.log('shortened href='+aHref);
            }
            if (aHref.includes('/')) {
                var aHref = aHref.substring(aHref.lastIndexOf('/') + 1)
            }
            for (j = 0; j < readArray.length; j++) {
                // console.log('testing '+readArray[j]+' and '+aHref);
                if (aHref == readArray[j]) {
                    // console.log(aHref + ' article has been read');
                    aLinks[i].classList.add('read');
                }
            }
        }
        // console.log(aLinks.length + " articles have been read");
    }
    // }
}

//SAVE STRING TO CLIPBOARD
//sourced from: https://techoverflow.net/2018/03/30/copying-strings-to-the-clipboard-using-pure-javascript/
export function copyStringToClipboard(str) {
    // Create new element
    var el = document.createElement('textarea');
    // Set value (string to be copied)
    el.value = str;
    // Set non-editable to avoid focus and move outside of view
    el.setAttribute('readonly', '');
    el.style = { position: 'absolute', left: '-9999px' };
    document.body.appendChild(el);
    // Select text inside element
    el.select();
    // Copy text to clipboard
    document.execCommand('copy');
    // Remove temporary element
    document.body.removeChild(el);
}

// UPDATE TOGGLES STATE
export function updateToggles(state, sharedClass) {
    var toggleElements = Array.prototype.slice.call(document.querySelectorAll(sharedClass), 0);;
    console.log(toggleElements);
    for (let i = 0; i < toggleElements.length; i++) {
        if (state == "on") {
            console.log("turning toggles on");
            toggleElements[i].setAttribute("checked", "checked"); //for IE
            toggleElements[i].checked = true;
        } else if (state == "off") {
            console.log("turning toggles off");
            toggleElements[i].setAttribute("checked", ""); //for IE
            toggleElements[i].removeAttribute("checked"); //for other browsers
            toggleElements[i].checked = false;
        }
    }
}

// TOAST MESSAGE
export function toast(message) {
    var x = document.getElementById("toast-ui");

    // Add the "show" class to DIV and put in message content
    x.innerHTML = message;
    x.className = "show";

    // After 3 seconds, remove the show class from DIV
    setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
}

// OPEN POPUP
export function openPopup(btn, popup, type) {
    btn.addEventListener('click', () => {
        if (type == "launch") {
            let name = getCookie("name");
            let prefs = getCookie("saveFilter");
            if (name != "") {
                if (prefs != "") {
                    window.location.href = "/myguide";
                } else {
                    window.location.href = "/survey";
                }
            } else {
                let overlay = document.querySelector("#overlay");
                overlay.classList.add("active");
                popup.classList.remove("display-none");
            }
        } else {
            let overlay = document.querySelector("#overlay");
            overlay.classList.add("active");
            popup.classList.remove("display-none");
        }
    });
}

// CLOSE POPUPS
export function closePopups() {
    let popups = document.querySelectorAll('.popup-container');
    let overlay = document.querySelector('#overlay');
    popups.forEach(e => {
        if (e.classList.contains("no-display-none")) {
            e.classList.add("invisible");
            if (e.id == "welcome-popup") {
                setCookie('seen-onboarding', true, 365);
            }
        } else {
            e.classList.add("display-none");
        }
    });
    overlay.classList.remove('active');
}