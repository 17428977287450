//import utility functions
import * as utility from "../helpers/utilities";

// Set event listeners on all toggles with utillity function: setToggleFunctions() and pass in favourites cookie update function
export default function () {
    document.addEventListener('DOMContentLoaded', () => {
        // ADD EVENT LISTENERS
        const removeFavBtns = Array.prototype.slice.call(document.querySelectorAll('.remove-fav-btn'), 0);
        if (removeFavBtns.length > 0) {
            removeFavBtns.forEach(el => {
                el.addEventListener('click', () => {
                    console.log(el.parentElement.parentElement);

                    // Get savedPages cookie
                    let savedPages = utility.getCookie('saved-pages');
                    let savedPagesString = "";
                    let thisPage = el.id;

                    let alreadySaved = false;
                    let currSaved = savedPages.split('&'); //split cookie into array

                    // check if already saved
                    currSaved.forEach(page => {
                        if (page === thisPage) {
                            alreadySaved = true;
                        }
                    });

                    // Rebuild string
                    currSaved.forEach(page => {
                        if (page === thisPage) {
                            // do not add add this time (removes it)
                            el.parentElement.parentElement.classList.add("display-none");
                        } else {
                            savedPagesString += page + '&';
                        }
                    });
                    savedPagesString = savedPagesString.substring(0, savedPagesString.length - 1);

                    utility.setCookie("saved-pages", savedPagesString, 365);
                })
            })
        }
    })
}