// SCRIPTS TO READ COOKIES AND UPDATE UI FOR READ HISTORY AND FAVOURITES

//import utility functions
import * as utility from "./helpers/utilities";

export default function () {
  //after document loads, add all open, close etc. eventlisteners
  document.addEventListener('DOMContentLoaded', () => {
    updateReadStatus();
  })
}

function updateReadStatus() {
  // console.log("running updateReadStatus");

  var aLinks = Array.prototype.slice.call(document.querySelectorAll('.subsection-link'), 0);
  var readCookie = utility.getCookie('readPages');
  var readArray = readCookie.split('&'); //split cookie into array

  if (aLinks.length > 0) {
    aLinks.forEach(a => {
      let href = a.getAttribute("href");
      let slug = href.split("/")[2];
      if (slug.includes("?")) {
        slug = slug.split("?")[0];
      }
      console.log(slug);
      readArray.forEach(page => {
        if (slug === page) {
          a.classList.add("read");
        }
      })
    });
  }
}